.sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  padding: 20px;
  height: 100vh; /* Fixed height for full-screen height */
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.sidebar h2 a {
  color: white;
  text-decoration: none;
  margin-bottom: 20px;
}

.sidebar h2 a:hover {
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 20px;
  cursor: pointer;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #c5c1c1;
  text-decoration: underline;
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.text-column {
  margin-left: 10px;
}

.sidebar .list-bottom {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
}

@media only screen and (max-width: 1099px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
  }

  .sidebar h2 {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #333;
    padding: 10px;
    text-align: center;
    z-index: 10; /* Ensure it stays on top */
    margin: 0;
  }

  .sidebar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 60px; /* Space below the fixed title */
  }

  .sidebar li {
    margin-bottom: 0;
    flex-grow: 1;
    text-align: center;
    padding: 10px;
  }

  .icon-column {
    width: auto;
    margin-right: 0;
  }

  .sidebar .list-bottom {
    position: static;
    margin-top: 0;
  }

  .icon-column {
    margin-left: 20px;
  }

}

@media only screen and (max-width: 599px) {
  .text-column{
    display: none;
  }
}