body {
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
  color: #333;
  padding: 0;
}

.person-data-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.person-table,
.financial-risk-table,
.apps-networks-table,
.gov-debtors-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.person-table thead,
.financial-risk-table thead,
.apps-networks-table thead,
.gov-debtors-table thead {
  background-color: #f4f4f4;
}

.person-table th,
.person-table td,
.financial-risk-table th,
.financial-risk-table td,
.apps-networks-table th,
.gov-debtors-table th,
.apps-networks-table td,
.gov-debtors-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.person-table th,
.financial-risk-table th,
.apps-networks-table th,
.gov-debtors-table th {
  text-align: left;
}

.person-details,
.financial-risk-details,
.apps-networks-detail,
.gov-debtors-detail {
  margin-top: 2rem;
  padding: 1rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-section {
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.loading {
  color: #007bff;
}

.error {
  color: red;
}