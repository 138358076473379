body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  padding: 0;
}

.company-data-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.search-input {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 70%;
  margin-right: 1rem;
}

.search-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
}

.loading {
  color: #007bff;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.error {
  color: red;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.company-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
}

.company-table th, .company-table td {
  padding: 0.75rem;
  border: 1px solid #ccc;
  text-align: left;
}

.company-table th {
  background-color: #f1f1f1;
}
