/* src/css/Home.css */

.home-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.hero-section {
  background: linear-gradient(135deg, #007bff, #00c6ff);
  color: white;
  padding: 60px 20px;
  text-align: center;
  border-bottom: 5px solid #0056b3;
  margin-bottom: 40px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 15px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.feature {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 15px 0;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.feature h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

/* Media query for larger screens */
@media (min-width: 1300px) {
  .content-section {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .feature {
    width: 45%;
    margin: 20px 0;
  }
}
